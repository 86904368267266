.footer-container {
    display: flex;
    justify-content: center;
    background: #1d2020;
    margin: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.26);
    z-index: 99;
}

.footer-link {
    font-size: 26px;
    color: #e7e6e7;
    margin: 10px;
    text-align: center;
}
