.test-container {
    background-color: #fff;
    margin: 64px 0;
    width: 80%;
    margin-left: 10%;
    min-height: 750px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.26);
}

.try-again-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}
